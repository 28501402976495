import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Timer from "../../components/Timer";
import { useAuthenticate } from "../../context/AuthContext";
import axios from "axios";
import { api_url } from "../../utils/data";
import Loading from "../../components/Loading/Loading";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import NoData from "../../components/NoData";
import Swal from "sweetalert2";
import LowStockNotificationModal from "./LowStockNotificationModal";
import ExpiringDrugsModal from "./ExpiringDrugsModal";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { authToken, user } = useAuthenticate();
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [loadingDashboardData, setLoadingDashboardData] = useState(true);
  const [dashboardData, setDashboardData] = useState({});
  const [chartData, setChartData] = useState(null);
  const [gettingData, setGettingData] = useState(false);
  const [takingAttendance, setTakingAttendance] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [expiringDrugsModal, setExpiringDrugsModal] = useState(false);

  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const sendNotificationMail = async (alertType) =>{
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    if(subscriptionDetails?.package !== "Alpha"){
      axios.get(`${api_url}/accounts/send_notification/${alertType}/`,config).then((response)=>{
        
      }).catch((error) => {
        console.error(error);
      })
    }else{
      console.log("Dont send")
    }
  }

  const getDashboardData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    await axios
      .get(`${api_url}/accounts/dashboard/`, config)
      .then((response) => {
        
        if(response.data?.low_stock_drugs?.length > 0){
          setNotificationModal(true)
          sendNotificationMail("low_stock");
        }
        if(response.data?.expiring_drugs?.length > 0){
          setExpiringDrugsModal(true);
          sendNotificationMail("expiry");
        }
        setDashboardData(response.data);
        setChartData({
          labels: response.data?.labels,
          datasets: [
            {
              label: "Sales",
              data: response.data?.order_statistics.map((data) => data?.amount),
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        });
        setLoadingDashboardData(false);
      })
      .catch((error) => {
        setLoadingDashboardData(false);
        if (error?.response?.status === 500) {
          return toast.error("Internal server error");
        }
        if (error?.response?.status === 400) {
          console.log(error);
          for (const property in error.response.data) {
            if (property !== "0") {
              toast.error(`${property}: ${error.response.data[property]}`);
            } else {
              toast.error(`${error.response.data[property]}`);
            }
          }
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const getSubscriptionDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(`${api_url}/subscription/pharmacy-subscription/`, config)
      .then((response) => {
        if(response?.data?.success){
          console.log(response.data)
          setSubscriptionDetails(response?.data?.subscription);
          getDashboardData()
        }else{
          window.location.href="/no-subscription"
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMonthData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    setGettingData(true);
    try {
      await axios
        .get(`${api_url}/sales/sale-report/month`, config)
        .then((response) => {
          setChartData({
            labels: response.data?.labels,
            datasets: [
              {
                label: "Sales",
                data: response.data?.sales_report.map((data) => data?.amount),
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
              },
            ],
          });
          setGettingData(false);
        });
    } catch (error) {
      setGettingData(false);
      toast.error("Failed to get data");
    }
  };

  const getYearData = async () => {
    // setLabels(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])
    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    setGettingData(true);
    try {
      await axios
        .get(`${api_url}/sales/sale-report/year`, config)
        .then((response) => {
          setChartData({
            labels: response.data?.labels,
            datasets: [
              {
                label: "Sales",
                data: response.data?.sales_report.map((data) => data?.amount),
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
              },
            ],
          });
          setGettingData(false);
        });
    } catch (error) {
      setGettingData(false);
      toast.error("Failed to get data");
    }
  };

  const getWeekData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    setGettingData(true);
    try {
      await axios
        .get(`${api_url}/sales/sale-report/week`, config)
        .then((response) => {
          setChartData({
            labels: response.data?.labels,
            datasets: [
              {
                label: "Sales",
                data: response.data?.sales_report.map((data) => data?.amount),
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
              },
            ],
          });
          setGettingData(false);
        });
    } catch (error) {
      setGettingData(false);
      toast.error("Failed to get data");
    }
  };

  const checkIn = () => {
    const error = (error) => {
      toast.error("Please allow access to your location");
    };

    if (navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition(async (position) => {
        setTakingAttendance(true);
        const accuracy = position.coords.accuracy;
        
        if (accuracy < 2000) {
          navigator.geolocation.clearWatch(watchId);
          setTakingAttendance(false);
          Swal.fire({
            icon: "error",
            title: "Inaccurate position",
            text: "We are not getting your accurate position. Please pin your location in google maps and try again.",
          });
        } else {
          navigator.geolocation.clearWatch(watchId);
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          };
          const body = JSON.stringify({
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          });
          await axios
            .post(`${api_url}/staff-management/attendance/check-in/`, body, config)
            .then((response) => {
              Swal.fire({
                icon: response.data.icon,
                title: response.data.title,
                text: response.data.text,
              });
              setTakingAttendance(false);
            })
            .catch((error) => {
              console.log(error);
              setTakingAttendance(false);
              if(error?.response?.status === 500){
                return toast.error("Internal server error");
              }
              if(error?.response?.status === 400){
                for(const property in error.response.data){
                  if(property !== "0"){
                    toast.error(`${property} error: ${error.response.data[property]}`)
                  }else{
                    toast.error(`${error.response.data[property]}`)
                  }
                }
              }else{
                toast.error("Something went wrong");
              }
            });
        }
      }, error);
    } else {
      toast.error(
        "Your browser does not support this feature, please use a different browser."
      );
    }

    // setTakingAttendance(true)

    // Swal.fire({
    //   title:"Welcome!",
    //   text:"You have successfully checked in",
    //   icon:"success"
    // })
  };

  const checkOut = () => {
    // setTakingAttendance(true)

    const error = (error) => {
      toast.error("Please allow access to your location");
    };

    if (navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition(async (position) => {
        setTakingAttendance(true);
        const accuracy = position.coords.accuracy;

        if (accuracy < 2000) {
          navigator.geolocation.clearWatch(watchId);
          setTakingAttendance(false);
          Swal.fire({
            icon: "error",
            title: "Inaccurate position",
            text: "We are not getting your accurate position. Please pin your location in google maps and try again.",
          });
        } else {
          navigator.geolocation.clearWatch(watchId);
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          };
          const body = JSON.stringify({
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          });
          await axios
            .put(`${api_url}/staff-management/attendance/check-out/`, body, config)
            .then((response) => {
              setTakingAttendance(false);
              Swal.fire({
                icon: response.data.icon,
                title: response.data.title,
                text: response.data.text,
              }).then(()=>{
                window.location.reload();
              });
            })
            .catch((error) => {
              setTakingAttendance(false);
              if(error?.response?.status === 500){
                return toast.error("Internal server error");
              }
              if(error?.response?.status === 400){
                for(const property in error.response.data){
                  if(property !== "0"){
                    toast.error(`${property} error: ${error.response.data[property]}`)
                  }else{
                    toast.error(`${error.response.data[property]}`)
                  }
                }
              }else{
                toast.error("Something went wrong");
              }
            });
        }
      }, error);
    } else {
      toast.error(
        "Your browser does not support this feature, please use a different browser."
      );
    }
  };

  useEffect(() => {
    getSubscriptionDetails()
    // getDashboardData();    
  }, []);

  if (loadingDashboardData) {
    return <Loading />;
  } else {
    return (
      <div className="footer-offset">
        <Helmet>
          <title>RxUltimate | Dashboard</title>
        </Helmet>
        {/* LOADING */}

        <main id="content" role="main" className="main main-page pointer-event">
          {takingAttendance && <Loading />}
          {
            notificationModal && <LowStockNotificationModal setModal={setNotificationModal} drugList={dashboardData?.low_stock_drugs}/>
          }
          {
            expiringDrugsModal && <ExpiringDrugsModal setModal={setExpiringDrugsModal} drugList={dashboardData?.expiring_drugs}/>
          }
          
          <div className="content container-fluid">
            <div className="page-header mb-0 pb-2 border-0">
              <h1 className="page-header-title text-107980">
                Welcome, {user?.first_name}
              </h1>
              <p className="welcome-msg">
                Monitor your business analytics and statistics
              </p>
            </div>
            <div className="card mb-10px">
              <div className="card-body">
                <div className="row g-2" id="order_stats">
                  <div className="col-sm-6 col-lg-3">
                    <Link className="dashboard--card h-100" to="/medicine/list">
                      <h6 className="subtitle">Stock Medicine</h6>
                      <h2 className="title">{dashboardData?.stock_medicine}</h2>
                      <img
                        src="https://grofresh-admin.6amtech.com/public/assets/admin/img/dashboard/pending.png"
                        alt=""
                        className="dashboard-icon"
                      />
                    </Link>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <Link className="dashboard--card h-100" to="/sales/list">
                      <h6 className="subtitle">Total Sales</h6>
                      <h2 className="title">{dashboardData?.total_sales}</h2>
                      <img
                        src="https://grofresh-admin.6amtech.com/public/assets/admin/img/dashboard/confirmed.png"
                        alt=""
                        className="dashboard-icon"
                      />
                    </Link>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <Link className="dashboard--card h-100" to="/medicine/list">
                      <h6 className="subtitle">Low Stock Medicine</h6>
                      <h2 className="title">{dashboardData?.low_stock}</h2>
                      <img
                        src="https://grofresh-admin.6amtech.com/public/assets/admin/img/dashboard/packaging.png"
                        alt=""
                        className="dashboard-icon"
                      />
                    </Link>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <Link className="dashboard--card h-100" to="/medicine/list">
                      <h6 className="subtitle">Out of stock medicine</h6>
                      <h2 className="title">{dashboardData?.out_of_stock}</h2>
                      <img
                        src="https://grofresh-admin.6amtech.com/public/assets/admin/img/dashboard/out-for-delivery.png"
                        alt=""
                        className="dashboard-icon"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-statistics">
              <div className="row g-1">
                {/* SALES STATISTICS */}
                <div className="col-lg-8 col--xl-8">
                  <div className="card h-100 bg-white">
                    <div className="card-body p-20px pb-0">
                      <div className="btn--container justify-content-between align-items-center">
                        <h5 className="card-title mb-2">
                          <img
                            src="https://grofresh-admin.6amtech.com/public/assets/admin/img/order-statistics.png"
                            alt=""
                            className="card-icon"
                          />
                          <span>Order statistics</span>
                        </h5>
                        <div className="mb-2">
                          <div className="d-flex flex-wrap statistics-btn-grp">
                            <label>
                              <input
                                type="radio"
                                name="order__statistics"
                                hidden
                                defaultChecked="true"
                              />
                              <span data-order-type="yearOrder" onClick={() => getYearData()}>This Year</span>
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="order__statistics"
                                hidden
                              />
                              <span data-order-type="MonthOrder" onClick={() => getMonthData()}>
                                This Month
                              </span>
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="order__statistics"
                                hidden
                              />
                              <span data-order-type="WeekOrder" onClick={() => getWeekData()}>This Week</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div id="updatingOrderData">
                        {!gettingData ? (
                          <div>
                            <Line options={options} data={chartData} />;
                          </div>
                        ) : (
                          <div
                            style={{
                              height: "400px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <RotatingLines
                              visible={true}
                              height="96"
                              width="96"
                              strokeColor="blue"
                              strokeWidth="5"
                              animationDuration="0.75"
                              ariaLabel="rotating-lines-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* RECENT SALES */}
                <div className="col-lg-4 col--xl-4">
                  <div className="card bg-white mb-3">
                    <div className="card-header border-0 order-header-shadow">
                      <h5 className="card-title d-flex justify-content-between flex-grow-1">
                        <span>Recent Sales</span>
                        <Link
                          className="fz-12px font-medium text-006AE5"
                          to="/sales/list"
                        >
                          View All
                        </Link>
                      </h5>
                    </div>
                    <div className="card-body p-10px">
                      <ul className="recent--orders">
                      {dashboardData?.recent_sales?.map((sale, index) => (
                          <li key={index}>
                            <Link to={`/sales/${sale.id}/details`}>
                              <div>
                                <h6>Sale #{sale.sale_id}</h6>
                                <span className="text-uppercase">
                                  {sale?.date} {sale?.time}
                                </span>
                              </div>
                              {
                                sale.payment_status ==="Part Payment" && (
                                  <span className="status text-b9565e">
                                    {sale.payment_status}
                                  </span>
                                )
                              }
                              {
                                sale.payment_status ==="Full Payment" && (
                                  <span className="status text-56b98f">
                                    {sale.payment_status}
                                  </span>
                                )
                              }
                            </Link>
                          </li>
                        ))}
                      </ul>
                      {dashboardData?.recent_sales.length <= 0 && (
                        <NoData />
                      )}
                    </div>
                  </div>
                  
                  {
                    subscriptionDetails?.package !== "Alpha" && (
                      <div className="card bg-white">
                        <div className="card-header border-0 order-header-shadow">
                          <h5 className="card-title d-flex justify-content-between flex-grow-1">
                            <span>Attendance</span>
                          </h5>
                        </div>
                        <div className="card-body p-10px ">
                          <Timer />

                          <button disabled={dashboardData?.check_in_time} onClick={() => checkIn()} className="btn btn-primary d-block mb-3 p-3 w-100">
                            Check In
                          </button>
                          <button disabled={dashboardData?.check_out_time} onClick={() => checkOut()} className="btn btn-danger p-3 d-block text-white w-100">
                            Check Out
                          </button>
                          
                          <div className="mt-4">
                            {
                              dashboardData?.check_in_time && (
                                <span>
                                  <b>Check In Time</b> : {dashboardData?.check_in_time}
                                </span>
                              )
                            }
                            {
                              dashboardData?.check_out_time && (
                                <p>
                                  <b>Check Out Time</b> : {dashboardData?.check_out_time}
                                </p>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>

                {/* TOP SELLING DRUGS */}
                <div className="col-lg-4">
                  <div className="card h-100">
                    <div className="card-header border-0 order-header-shadow">
                      <h5 className="card-title d-flex justify-content-between flex-grow-1">
                        <span>Top selling drugs</span>
                        {/* <Link
                              to="#"
                              className="fz-12px font-medium text-006AE5"
                            >
                              View All
                            </Link> */}
                      </h5>
                    </div>
                    <div className="card-body">
                      {dashboardData?.top_selling_drugs?.map((drug, index) => (
                        <div className="top--selling" key={index}>
                          <Link className="grid--card" to={`/medicine/${drug?.id}/details`}>
                            
                            <div className="cont pt-2">
                              <h6 className="line--limit-2">{drug?.name}</h6>
                            </div>
                            <div className="ml-auto">
                              <span className="badge badge-soft">
                                Sold : {drug?.number_of_sales}
                              </span>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                    {dashboardData?.top_selling_drugs?.length <= 0 && (
                      <NoData />
                    )}
                  </div>
                </div>

                {/* OUT OF STOCK DRUGS */}
                <div className="col-lg-4">
                  <div className="card h-100">
                    <div className="card-header border-0 order-header-shadow">
                      <h5 className="card-title d-flex justify-content-between flex-grow-1">
                        <span>Out Of Stock Drugs</span>
                      </h5>
                    </div>
                    <div className="card-body">
                      {dashboardData?.out_of_stock_drugs?.map((drug, index) => (
                        <div className="top--selling" key={index}>
                          <Link className="grid--card" to={`/medicine/${drug?.id}/details`}>
                            
                            <div className="cont pt-2">
                              <h6 className="line--limit-2">{drug?.name}</h6>
                            </div>
                            <div className="ml-auto">
                              <span className="badge badge-soft">
                                Left : {drug?.quantity}
                              </span>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                    {dashboardData?.out_of_stock_drugs?.length <= 0 && (
                      <NoData />
                    )}
                  </div>
                </div>

                {/* LOW STOCK DRUGS */}
                <div className="col-lg-4">
                  <div className="card h-100">
                    <div className="card-header border-0 order-header-shadow">
                      <h5 className="card-title d-flex justify-content-between flex-grow-1">
                        <span>Low Stock Drugs</span>
                        {/* <Link
                              to="/medicine/list"
                              className="fz-12px font-medium text-006AE5"
                            >
                              View All
                            </Link> */}
                      </h5>
                    </div>
                    <div className="card-body">
                      {dashboardData?.low_stock_drugs?.map((drug, index) => (
                        <div className="top--selling" key={index}>
                          <Link className="grid--card" to={`/medicine/${drug?.id}/details`}>
                            
                            <div className="cont pt-2">
                              <h6 className="line--limit-2">{drug?.name}</h6>
                            </div>
                            <div className="ml-auto">
                              <span className="badge badge-soft">
                                Left : {drug?.quantity}
                              </span>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                    {dashboardData?.low_stock_drugs?.length <= 0 && <NoData />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
};

export default Dashboard;
